import React from 'react';
import PropTypes from 'prop-types';
import { 
    withRouter,
} from "react-router-dom";
import Navigation from "services/navigation.service.js";
import JWT from "services/jwt.service.js";
import Theme from "theme/main.theme.js";
import {PrimaryButton, CircleButton} from "components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import "./sidebar.style.css";

class SideBar extends React.Component{
    constructor(props) {
        super(props);
        this.state={
        };
        this.logout = this.logout.bind(this);
    }
    

    static propTypes={
        history: PropTypes.object.isRequired,
        sideBarCloseFunc:PropTypes.func,
    };
    

    logout(){
        JWT.clearToken();
        this.props.history.push(Navigation.paths.login);
    }


  render(){
    return (
      <div
        className={this.props.open ? "open-animation" : "close-animation"}
        style={{
          flexShrink: "0",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(55, 121, 243, 0.6)",
          width: "255px",
          padding: "0px 20px 0px 20px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px"
          }}
        >
          <CircleButton
            backgroundColor={Theme.color1}
            color={"white"}
            onClick={this.props.sideBarCloseFunc}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </CircleButton>
        </div>
        <div
          style={{
            marginTop: "75px",
            marginBottom: "35px"
          }}
        >
          <PrimaryButton
            small={true}
            onClick={() => this.props.history.push(Navigation.paths.root)}
          >
            Home
          </PrimaryButton>
        </div>
        <div
          style={{
            marginBottom: "35px"
          }}
        >
          <PrimaryButton
            small={true}
            onClick={() => this.props.history.push(Navigation.paths.sponsors)}
          >
            Sponsors
          </PrimaryButton>
        </div>
        <div
          style={{
            marginBottom: "35px"
          }}
        >
          <PrimaryButton small={true} onClick={this.logout}>
            Logout
          </PrimaryButton>
        </div>
      </div>
    );
  }
}


export default withRouter(SideBar);
