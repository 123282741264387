import URLService from "services/url.service.js";
import JWT from "services/jwt.service.js";
import axios, { post, put } from "axios";
import { ToastContainer, toast } from "react-toastify";


class HTTPService{
    static async _httpGET(url){
        // Check if token expired, and refresh if so
        await HTTPService.checkAndRefreshToken();
        
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'GET',
            headers: headers,
          });
        
        if(response.ok){
            let responseJson = await response.json();
            return responseJson;
        }else if(response.status === 401 || response.status === 403){
            // Redirect to Login
            throw response;
        }else{
            throw response;
        }

    }

    static async _httpPOST(url,json,checkToken=true,logoutOn401=true){
        // Check if token expired, and refresh if so
        if(checkToken){
            await HTTPService.checkAndRefreshToken();
        }

        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'POST',
            headers: headers,
            body:JSON.stringify(json),
          });
        if(response.ok){
            try {              
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if((response.status === 401 || response.status === 403) && logoutOn401){
            // Redirect to Login
            throw response;
        }else{
            throw response;
        }

    }
/// for sponsor and other data uploads
///////////////////
    static async _httpFormDataPOST(url, files, stringifiedJSON, checkToken = true, logoutOn401 = true){
        if (checkToken) {
            await HTTPService.checkAndRefreshToken();
        }
         const formData = new FormData();
         formData.append('files', files);
         formData.append('json', stringifiedJSON);
         const config = {
           headers: {
            "Authorization": `token ${JWT.getTokenFromStorage()}`,
            "content-type": "multipart/form-data"
           }
         };       
         return post(url, formData, config).then((response) => {
             //success
            console.log(response)
            return response;
         }).catch((error) => {
             //error
             if (error.response){
               console.log(error.response.data);
               console.log(error.response.status);
               console.log(error.response.headers);  
             }else if (error.request) {
                console.log(error.request);
             }else {
                console.log("Error", error.message);
             }
             console.log(error.config);
             return "Failed";
         });
        
    }

    static async _httpFormDataPUT(url, files, stringifiedJSON, checkToken = true, logoutOn401 = true){
        if (checkToken) {
            await HTTPService.checkAndRefreshToken();
        }
         const formData = new FormData();
         formData.append('files', files);
         formData.append('json', stringifiedJSON);
         const config = {
           headers: {
            "Authorization": `token ${JWT.getTokenFromStorage()}`,
            "content-type": "multipart/form-data"
           }
         };       
         return put(url, formData, config)
           .then(response => {
             //success
             console.log(response);
           })
           .catch(error => {
             //error
             if (error.response) {
               console.log(error.response.data);
               console.log(error.response.status);
               console.log(error.response.headers);
             } else if (error.request) {
               console.log(error.request);
             } else {
               console.log("Error", error.message);
             }
             console.log(error.config);
             return "Failed"
           });;

    }
///////////////////
///////////////////
    static async _httpPUT(url,json,checkToken=true,logoutOn401=true){
        // Check if token expired, and refresh if so
        if(checkToken){
            await HTTPService.checkAndRefreshToken();
        }
    
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'PUT',
            headers: headers,
            body:JSON.stringify(json),
          });
        
        if(response.ok){
            try {                
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if((response.status === 401 || response.status === 403) && logoutOn401){
            // Redirect to Login
            throw response;
        }else{
            throw response;
        }

    }
    
    static async _httpDELETE(url){
        // Check if token expired, and refresh if so
        await HTTPService.checkAndRefreshToken();
        
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'DELETE',
            headers: headers,
          });
        
        if(response.ok){
            try {                
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if(response.status === 401 || response.status === 403){
            // Redirect to Login
            throw response;
        }else{
            throw response;
        }

    }

    

    // Auth
    static async login(email, password){
        let response = await HTTPService._httpPOST(URLService.login(),{email:email,password:password},false,false);
        return response;
    }

    static async logout(){
        let response = await HTTPService._httpPOST(URLService.logout(),{});
        return response;
    }

    static async register(email, password){
        let response = await HTTPService._httpPOST(URLService.users(),{email:email,password:password},false,false);
        return response;
    }

    static async forgotPassword(email){
        let response = await HTTPService._httpPOST(URLService.forgotPassword(),{email:email},false,false);
        return response;
    }

    static async changePassword(password){
        let response = await HTTPService._httpPUT(URLService.changePassword(),{"new_password":password});
        return response;
    }

    static async updateEmail(email){
        let response = await HTTPService._httpPUT(URLService.user(JWT.getUserID()),{email});
        return response;
    }

    static async deleteUser(){
        let response = await HTTPService._httpDELETE(URLService.user(JWT.getUserID()));
        return response;
    }


    static async checkAndRefreshToken(){
        if(JWT.isTokenExpired()){
          console.log("token expired, refreshing");
          try {
              let responseJson = await HTTPService._httpPOST(URLService.refreshToken(),{},false,false);
              JWT.storeToken(responseJson["token"]);
          } catch (error) {
                console.error(error);  
                // TODO: navigate to login
                // NavigationService.toLoginRoute();
          }
        }
        return;
    }

    //LOST DOG -Development
    static async getSponsors() {
        let response = await HTTPService._httpGET(URLService.sponsors());
        return response;
    }

    static async newSponsor(files, json) {
        let stringifiedJSON = JSON.stringify(json);
        let response = await HTTPService._httpFormDataPOST(URLService.sponsors(),files, stringifiedJSON);
        if(response){
         return response;
        }
    }

    static async getSponsor(sponsorId) {
        let response = await HTTPService._httpGET(URLService.sponsor(sponsorId));
        return response;
    }

    static async editSponsor(files, json, sponsorId) {
        let stringifiedJSON = JSON.stringify(json);
        let response = await HTTPService._httpFormDataPUT(URLService.sponsor(sponsorId),files, stringifiedJSON);
         return response;
    } 
    
    static async deleteSponsor(sponsorId) {
        let response = await HTTPService._httpDELETE(URLService.sponsor(sponsorId));
        return response;
    }
}

export default HTTPService;