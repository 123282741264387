import React from 'react';
import {
  withRouter,
} from "react-router-dom";
import HTTPService from "services/http.service";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service";
import {
  Loader
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SponsorForm from "./components/sponsor.form";
require("./components/sponsor.create.css")
///////////////////////////////////
///////date chosen for expiring as placeholder for now
let testDate = new Date(2020, 11, 24);
///////////////////////////////////
class SponsorCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
        url: "",
        expires: testDate,
        tier: "LOW",
        files: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // FORM INPUT HANDLER
  handleChange(field) {
    return (e) => {
      this.setState({ [field]: e.target.value })
    }
  }

  handleChangeFile(e){
    this.setState({ files: e.target.files[0] });
  }


  // FORM SUBMIT ACTION
   async handleSubmit(e) {
    e.preventDefault();
    let { name, url, expires, tier} = {...this.state}
    await HTTPService.newSponsor(this.state.files, {name, url, expires, tier});
    this.props.history.push(Navigation.paths.sponsors);
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "1em"
          }}
        >
          Sponsor Add
        </h1>
        <div className="button-container">
          <div
            className="delete-button arrow"
            onClick={() => this.props.history.push(Navigation.paths.sponsors)}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              color="black"
              transform="grow-1.5"
            />
            Sponsors
          </div>
        </div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <SponsorForm type={"Add"} propState={this.state} />
        )}
      </div>
    );
  }
}


export default withRouter(SponsorCreate);