import React from 'react';
import {
  withRouter,
} from "react-router-dom";
import HTTPService from "services/http.service";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service";
import {
  Loader,
  ConfirmDialog
} from "components";
import SponsorForm from "./components/sponsor.form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
require("./components/sponsor.create.css")


class SponsorEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      tier: "LOW",
      files: [],
      loading: false, 
      showConfirmDialog: false
    };
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.id = this.props.match.params.id;
  }

  // FORM INPUT HANDLER
  handleChange(field) {
    return e => {
      this.setState({ [field]: e.target.value });
    };
  }

  handleChangeFile(e) {
    this.setState({ files: e.target.files[0] });
  }

  // FORM SUBMIT ACTION
  async handleSubmit(e) {
    e.preventDefault();
    let { name, url, tier } = { ...this.state };
    await HTTPService.editSponsor(
      this.state.files,
      {
        name,
        url,
        tier
      },
      this.id
    );
    this.props.history.push(Navigation.paths.sponsors);
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <ConfirmDialog
          show={this.state.showConfirmDialog}
          onDismiss={() => this.setState({ showConfirmDialog: false })}
          title={"Delete Sponsor"}
          body={"Are you sure you want to delete this sponsor?"}
          actionTitle={"YES"}
          actionFunction={() => {
            HTTPService.deleteSponsor(this.id);
            this.setState({ showConfirmDialog: false });
            this.props.history.push(Navigation.paths.sponsors);
          }}
        />
        <h1
          style={{
            textAlign: "center",
            marginTop: "1em"
          }}
        >
          Sponsor Edit: {this.id}
        </h1>
        <div className="button-container">
          <div
            className="delete-button arrow"
            onClick={() => this.props.history.push(Navigation.paths.sponsors)}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              color="black"
              transform="grow-1.5"
            />
               Sponsors
          </div>
          <div
            className="delete-button"
            onClick={() => this.setState({ showConfirmDialog: true })}
          >
            <FontAwesomeIcon icon={faTrash} color="black" />
          </div>
        </div>
        {this.state.loading ? (
          <Loader />
        ) : (
        <SponsorForm type={"Edit"} propState={this.state} />
        )}
      </div>
    );
  }
}


export default withRouter(SponsorEdit);
