class URLService{
    // change host based on dev vs prod
    // cahnged to local dev server on api to 00008080
    // still having cors errors
    static host = process.env.NODE_ENV === 'production' ? "https://api.dog-gone.org/api" :"http://0.0.0.0:8080/api";

    static usersBase ="users";
    static sponsorsBase = "sponsors"
    static authBase ="auth";

    static users = () => `${URLService.host}/${URLService.usersBase}`;
    static user = (firebaseId) => `${URLService.host}/${URLService.usersBase}/${firebaseId}`;

    static login = () => `${URLService.host}/${URLService.authBase}/login`;
    static forgotPassword = () => `${URLService.host}/${URLService.authBase}/forgot-password`;
    static logout= () => `${URLService.host}/${URLService.authBase}/logout`;
    static changePassword = () => `${URLService.host}/${URLService.authBase}/change-password`;
    static refreshToken= () => `${URLService.host}/${URLService.authBase}/refresh-token`;

    //Lost Dog Code - Development
    static sponsors = () => `${URLService.host}/${URLService.sponsorsBase}`;
    static sponsor = (sponsorId) => `${URLService.host}/${URLService.sponsorsBase}/${sponsorId}`;
}

export default URLService;

