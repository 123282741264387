
class Navigation{
    static paths = {
        root:"/",
        auth:"/auth",
        login:"/auth/login",
        register:"/auth/register",
        forgotPassword:"/auth/forgot-password",
        sponsors: "/sponsors",
        sponsorEdit: "/sponsors/:id/edit",
        sponsorCreate: "/sponsors/create",
    };

}

export default Navigation;