import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigation from "services/navigation.service";
import {
  PrimaryButton
} from "components";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConfirmDialog: false,
      showInputDialog: false,
      dialogInput: ""
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };
 
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "1em"
          }}
        >
          Home
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          <div
            style={{
              width: "200px",
              margin: "40px 10px"
            }}
          >
            <PrimaryButton
              small={true}
              onClick={() => this.props.history.push(Navigation.paths.sponsors)}
            >
              Sponsors
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
