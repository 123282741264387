// later for DRYER code make a form component that state will feed into probs
import React from "react";
import { withRouter } from "react-router-dom";
import HTTPService from "services/http.service";
import PropTypes from "prop-types";
import Navigation from "services/navigation.service";
import { Loader } from "components";
import { ToastContainer, toast } from "react-toastify";
require("./sponsor.create.css");

class SponsorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.propState.name || "",
      url: this.props.propState.url || "",
      tier: this.props.propState.tier || "LOW",
      files: this.props.propState.files || [],
      loading: this.props.propState.loading || false,
      expires: this.props.propState.expires || "",
      imgPreview: this.props.propState.files[0] || null
    };
    this.type = this.props.type;
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchSponsor = this.fetchSponsor.bind(this);
    this.id = this.props.match.params.id;
  }

  // FORM INPUT HANDLER
  handleChange(field) {
    return e => {
      this.setState({ [field]: e.target.value });
    };
  }

  handleChangeFile(e) {
    this.setState({ files: e.target.files[0], imgPreview: URL.createObjectURL(e.target.files[0])});
  }

  componentDidMount() {
    this.fetchSponsor();
  }

// regex check for url to hopefully stop more problems here before attempting post/put
  ValidUrl(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  async fetchSponsor() {
    if (this.type === "Edit"){
      this.setState({ loading: true });
      let sponsor = await HTTPService.getSponsor(this.id);
      this.setState({
        name: sponsor.name,
        url: sponsor.url,
        tier: sponsor.tier,
        files: [sponsor.banner],
        imgPreview: sponsor.banner,
        loading: false
      });
    }
  }

  // FORM SUBMIT ACTION
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({loading:true})
    let i = 0;
  //Edit FORM SUBMIT
    if(this.type === "Edit"){
      let { name, url, tier } = { ...this.state };
    // Specified Toast Errors
      if (name === "") {
        toast.error("Name cannot be blank", {
          position: toast.POSITION.TOP_RIGHT
        });
        i += 1;
      }

      if (url === "") {
        toast.error("Url cannot be blank", {
          position: toast.POSITION.TOP_RIGHT
        });
        i += 1;
      }

      if (!this.ValidUrl(url)) {
        toast.error(
          "Not a valid url; correct format: https://www.something.com",
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
        i += 1;
      }

    //error check in editform
      if( i > 0 ){
        this.setState({ loading: false });
        return
      } else{
        let status = await HTTPService.editSponsor(
          this.state.files,
          {
            name,
            url,
            tier
          },
          this.id
        );
        if (status === "Failed") {
          toast.error(
            "Something Went Wrong, check form and try again",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({ loading: false });
        }else{
          this.setState({ loading: false });
          this.props.history.push(Navigation.paths.sponsors);
        }
      }
    } else{
//Add FORM SUBMIT
          let { name, url, expires, tier } = { ...this.state };
    // Specified Toast Errors
          if(name === ""){
            toast.error("Name cannot be blank", {
              position: toast.POSITION.TOP_RIGHT
            });
            i += 1;
          }
          if (url === "") {
            toast.error("Url cannot be blank", {
              position: toast.POSITION.TOP_RIGHT
            });
            i +=1;
          }
          if (!this.ValidUrl(url)) {
             toast.error(
               "Not a valid url; correct format: https://www.something.com",
               {
                 position: toast.POSITION.TOP_RIGHT
               }
             );
             i += 1;
          }
          if (expires === "") {
            toast.error("Expiration cannot be blank", {
              position: toast.POSITION.TOP_RIGHT
            });
            i += 1;
          }
      //error check in addform
          if( i > 0 ){
            this.setState({ loading: false });
            return
          } else{
             let status = await HTTPService.newSponsor(this.state.files, {
               name,
               url,
               expires,
               tier
             });
              if (status === "Failed"){
                toast.error(
                  "Something Went Wrong, check form and try again",
                  {
                    position: toast.POSITION.TOP_RIGHT
                  }
                );
                this.setState({ loading: false });
              } else{
                this.setState({ loading: false });
                this.props.history.push(Navigation.paths.sponsors);
              }
          }
      }
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="sponsor-create-container">
            <form className="sponsor-create-form" onSubmit={this.handleSubmit}>
              <span className="create-form-input-label">Name</span>
              <input
                className="create-form-input name"
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange("name")}
              />
              <span className="create-form-input-label">Tier</span>
              <select
                className="create-form-input tier"
                value={this.state.tier}
                onChange={this.handleChange("tier")}
              >
                <option value="LOW">LOW</option>
                <option value="MID">MID</option>
                <option value="HIGH">HIGH</option>
              </select>
              <span className="create-form-input-label">Link URL</span>
              <input
                className="create-form-input link-url"
                type="url"
                placeholder="URL"
                value={this.state.url}
                onChange={this.handleChange("url")}
              />

              <span className="create-form-input-label">
                Banner Image (320x50) <br></br>
                <img id="preview" className="banner-preview" src={this.state.imgPreview} alt="No image"></img>
              </span>

              <input
                // className="create-form-input"
                type="file"
                onChange={this.handleChangeFile}
              />

              <input
                className="create-form-input submit-button"
                type="submit"
                value={this.type}
              />
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SponsorForm);