import React from 'react';
import {
  withRouter,
} from "react-router-dom";
import HTTPService from "services/http.service";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service";
import {
  PrimaryButton,
  Loader,
} from "components";
require("./components/sponsor.list.css")

class SponsorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConfirmDialog: false,
      showInputDialog: false,
      dialogInput: "",
      sponsors:[]
    };
    this.updateSponsors = this.updateSponsors.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };
  componentDidMount() {
    this.updateSponsors();
  }

  componentWillUnmount() {
  }

// Fetch sponsor data 
  async updateSponsors() {
    this.setState({ loading: true });
    let sponsors = await HTTPService.getSponsors();
    this.setState({ sponsors: sponsors, loading: false });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "1em"
          }}
        >
          Sponsors
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            flexWrap: "wrap",
            flexDirection: "row-reverse"
          }}
        >
          <div
            style={{
              width: "200px",
              margin: "40px 10px",
              marginBottom: "1em"
            }}
          >
            <PrimaryButton
              small={true}
              onClick={() =>
                this.props.history.push(Navigation.paths.sponsorCreate)
              }
            >
              Add Sponsor
            </PrimaryButton>
          </div>
        </div>
        {this.state.loading ? (
          <Loader />
        ) : this.state.sponsors.length === 0 ? (
          <h1 style={{ textAlign: "center" }}>No Sponsors Yet</h1>
        ) : (
          <div className="sponsor-list-container">
            {/* sorted by id but may be unnecessary and bad time */}
            {this.state.sponsors.sort((a, b) => (a.id > b.id) ? 1 : -1).map(sponsor => {
              return (
                <div
                  key={sponsor.id}
                  className="sponsor-list-item"
                  onClick={() =>
                    this.props.history.push(`/sponsors/${sponsor.id}/edit`)
                  }
                >
                  <div className="sponsor-details">
                    <div className="sponsor-name">{sponsor.name}</div>
                    <div className="sponsor-tier">{sponsor.tier}</div>
                    <div className="sponsor-url">{sponsor.url}</div>
                    <div className="sponsor-banner">
                      <img
                        className="banner-image"
                        src={sponsor.banner}
                        alt={"No image"}
                      />
                    </div>
                    <div className="sponsor-id">{sponsor.id}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}


export default withRouter(SponsorList);